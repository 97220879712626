export const sortFavorites = (favorites) => {
  const sortedFavorites = [...favorites].sort((a, b) => {
    if (!a.favoriteName || !b.favoriteName) {
      if (!a.favoriteName && b.favoriteName) return -1;
      if (a.favoriteName && !b.favoriteName) return 1;
    }
    return a.favoriteName.localeCompare(b.favoriteName);
  });

  return sortedFavorites;
};

export const tableKeyTitles = {
  createUpdate: "",
  favoriteName: "Medicine name",
  clientName: "Medicine Id",
  medication: "Medication",
  type: "Type",
  strength: "Strength",
  sig: "Sig",
  quantity: "Quantity",
  dispenseUnit: "Dispense",
  refills: "Refills",
  daysSupply: "Days",
  category: "Category",
  pharmacyNotes: "Pharmacy Notes",
  visitType: "Visit Type",
  pharmacyId: "Pharmacy ID",
};
