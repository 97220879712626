import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import CustomTable from "../Components/CustomTable";
import { sortFavorites, tableKeyTitles } from "./utils";

const FavoritesTable = (props) => {
  const { favorites = [] } = props;

  const favoriteList = sortFavorites(favorites);

  const rowOverrides = {
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      <CustomTable
        list={favoriteList}
        getRowKey={(element) => element._id}
        tableKeyTitles={tableKeyTitles}
        rowOverrides={rowOverrides}
      />
    </div>
  );
};
FavoritesTable.propTypes = {
  user: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  onSuccess: PropTypes.func,
};

export default FavoritesTable;
