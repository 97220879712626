import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Favorites from "../Favorites/Favorites";
import VisitsAtCompany from "../VisitsAtCompany/VisitsAtCompany";
import { getFavorites } from "../utils/router";

import "./Dashboard.css";

const Dashboard = (props) => {
  const { user } = props;

  const [currentTab, setCurrentTab] = useState("");
  const [favorites, setFavorites] = useState([]);
  const [error, setError] = useState("");

  const handleFetchFavorites = async () => {
    await getFavorites(user.token, setFavorites).then((favorites) => {
      if (favorites?.error) {
        setError("Error fetching favorites");
      }
    });
  };

  useEffect(() => {
    const asyncFav = async () => {
      await handleFetchFavorites();
    };
    if (currentTab === "favorites") {
      asyncFav();
    }
  }, [currentTab, user]);

  return (
    <div className="dashboard-page">
      <Tabs
        defaultActiveKey="favorites"
        id="dashboard-tabs"
        justify
        onSelect={setCurrentTab}
      >
        <Tab eventKey="favorites" title="Favorites">
          <Favorites
            user={user}
            favorites={favorites}
            error={error}
            handleFetch={handleFetchFavorites}
          />
        </Tab>
        <Tab eventKey="visits" title="Visits">
          <VisitsAtCompany apiKey={user.token} />
        </Tab>
      </Tabs>
    </div>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Dashboard;
